import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { SvgIcon } from "../../atoms/SvgIcon";

const useStyles = makeStyles({
	root: {
		height: "75vh",
		width: "100%",
	},
});

const ManBigIcon = () => {
	const styles = useStyles();
	return (
		<SvgIcon className={styles.root} viewBox={"0 0 512 583"}>
			<svg xmlns='http://www.w3.org/2000/svg'>
				<defs>
					<linearGradient x1='50%' y1='0%' x2='50%' y2='100%' id='a'>
						<stop stopColor='#53629B' offset='0%' />
						<stop stopColor='#515790' offset='100%' />
					</linearGradient>
					<linearGradient x1='50%' y1='100%' x2='50%' y2='0%' id='b'>
						<stop stopColor='#D33645' offset='0%' />
						<stop stopColor='#FF3000' offset='100%' />
					</linearGradient>
				</defs>
				<g fillRule='nonzero' fill='none'>
					<path
						d='M192.642 308.744v25.664c0 34.22-48.842 49.19-48.842 49.19s56.277 42.775 114.676 42.775c58.4 0 114.677-42.776 114.677-42.776s-49.229-14.968-48.844-49.189c.384-34.22.127-54.321.384-112.948 30.436-39.731 28.483-66.396 11.84-85.148-55.759-62.824-207.6-41.437-207.6-41.437s-14.169 153.97 18.052 198.897c13.803 19.248 45.657 14.972 45.657 14.972z'
						fill='#FFDBC9'
					/>
					<path
						d='M192.533 308.903c46.365-5.44 73.763-26.104 73.763-26.104-17.298 26.414-43.659 45.072-73.763 52.208v-26.104z'
						fill='#FFBC9E'
					/>
					<path
						d='M480 445.574c13.54 27.71 24.207 73.518 32 137.426H0c7.797-63.915 18.463-109.723 32-137.426 20.305-41.556 138.88-80.098 138.88-80.098 22.854 47.225 148.285 47.225 170.18-.013 0 0 118.63 38.545 138.94 80.111z'
						fill='url(#a)'
						transform='matrix(-1 0 0 1 512 0)'
					/>
					<path
						d='M264.678 530.79c0-1.72-.509-3.403-1.463-4.833a8.655 8.655 0 0 0-3.894-3.204 8.649 8.649 0 0 0-5.014-.496 8.684 8.684 0 0 0-6.818 6.836 8.702 8.702 0 0 0 3.69 8.934 8.668 8.668 0 0 0 10.956-1.083 8.711 8.711 0 0 0 2.543-6.154zm0-43.506a8.697 8.697 0 0 0-5.357-8.04 8.647 8.647 0 0 0-5.014-.493 8.68 8.68 0 0 0-6.818 6.836 8.734 8.734 0 0 0 .491 5.025 8.685 8.685 0 0 0 3.2 3.906 8.658 8.658 0 0 0 10.956-1.08 8.717 8.717 0 0 0 2.542-6.154zm0-43.506a8.697 8.697 0 0 0-5.357-8.04 8.649 8.649 0 0 0-5.014-.496 8.684 8.684 0 0 0-6.818 6.836 8.702 8.702 0 0 0 3.69 8.934 8.668 8.668 0 0 0 10.956-1.083 8.71 8.71 0 0 0 2.543-6.151z'
						fill='#FFF'
					/>
					<path
						d='M213.825 156.605c-6.799-5.842-15.06-9.776-26.739-3.705-27.686 14.38-6.733 69.569 17.855 69.569 6.415 0 9.604 20.154-18.954 34.225 0-22.46-34.21-78.076-37.202-128.712-34.424-12.824-25.871-64.134-2.239-64.134-3.772-47.034 71.513-71.33 100.663-44.336 29.647-35.55 104.479-17.367 108.998 13.474 51.458-27.481 85.28 51.513 37.1 82.179-19.827 12.62-66.205 17.422-133.157 17.422-10.106 0-15.767 2.511-12.94 15.014 5.47 24.228-7.232 39.488-33.385 9.004z'
						fill='url(#b)'
						transform='matrix(-1 0 0 1 512 0)'
					/>
				</g>
			</svg>
		</SvgIcon>
	);
};

export { ManBigIcon };
