import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { SvgIcon } from "../../atoms/SvgIcon";

const useStyles = makeStyles({
	root: {
		width: "11.15625rem",
		height: "100%",
	},
});

const PeopleIcon = () => {
	const styles = useStyles();

	return (
		<SvgIcon className={styles.root} viewBox={"0 0 179 114"}>
			<defs>
				<linearGradient x1='50%' y1='0%' x2='50%' y2='100%' id='a'>
					<stop stopColor='#53629B' offset='0%' />
					<stop stopColor='#515790' offset='100%' />
				</linearGradient>
				<linearGradient x1='50%' y1='100%' x2='50%' y2='0%' id='b'>
					<stop stopColor='#D33645' offset='0%' />
					<stop stopColor='#FF3000' offset='100%' />
				</linearGradient>
				<linearGradient x1='50%' y1='0%' x2='50%' y2='100%' id='c'>
					<stop stopColor='#53629B' offset='0%' />
					<stop stopColor='#515790' offset='100%' />
				</linearGradient>
				<linearGradient x1='50%' y1='100%' x2='50%' y2='0%' id='d'>
					<stop stopColor='#D61B31' offset='0%' />
					<stop stopColor='#FF3000' offset='100%' />
				</linearGradient>
			</defs>
			<g fillRule='nonzero' fill='none'>
				<path
					d='M36.883 60.372v5.018c0 6.692-9.577 9.619-9.577 9.619s11.035 8.364 22.485 8.364 22.485-8.364 22.485-8.364-9.652-2.927-9.577-9.619c.075-6.691.025-10.622.075-22.086 5.968-7.769 5.585-12.983 2.322-16.65-10.933-12.284-40.704-8.102-40.704-8.102s-2.779 30.107 3.539 38.892c2.706 3.764 8.952 2.928 8.952 2.928z'
					fill='#FFDBC9'
				/>
				<path
					d='M36.862 60.403c9.09-1.064 14.462-5.104 14.462-5.104-3.391 5.165-8.56 8.813-14.462 10.208v-5.104z'
					fill='#FFBC9E'
				/>
				<path
					d='m57.034 71.463 1.104.373 1.39.49 1.544.569 1.494.57.971.381.675.27 1.404.578 1.093.463 1.12.489.757.339.764.349.768.359.77.368.771.378c.129.063.257.127.385.192l.767.39.762.4.755.407.745.414.734.422c3.88 2.27 7.182 4.836 8.47 7.464 1.5 3.062 2.82 7.254 3.96 12.577l.26 1.248.254 1.288.125.66.244 1.348.12.69.234 1.409.115.72A6 6 0 0 1 83.662 114H5.92l-.033-.46-.144-1.806-.094-1.082-.161-1.744c-.29-3.026-.63-6.053-1.024-8.938l-.216-1.533-.224-1.494-.184-1.147-.187-1.107-.191-1.064-.196-1.022c-.728-3.67-1.556-6.49-2.57-8.428A12.08 12.08 0 0 0 0 83.012c1.418-1.169 3.11-2.297 4.927-3.36l.734-.42.745-.415.754-.407.761-.398.766-.39.77-.382.77-.373.768-.363.766-.353 1.137-.512.375-.165 1.11-.48 1.081-.454 1.046-.427 1.325-.528.934-.361 1.42-.535 1.648-.596 1.661-.572.17-.056c4.48 9.235 29.074 9.235 33.366-.002z'
					fill='url(#a)'
					transform='matrix(-1 0 0 1 89.662 0)'
				/>
				<path
					d='M51.007 103.79a1.7 1.7 0 0 0-2.905-1.202 1.696 1.696 0 0 0-.369 1.854 1.703 1.703 0 0 0 3.274-.651zm0-8.506a1.704 1.704 0 0 0-1.05-1.573 1.7 1.7 0 0 0-2.32 1.24 1.704 1.704 0 0 0 1.668 2.034 1.702 1.702 0 0 0 1.702-1.701zm0-8.508a1.702 1.702 0 1 0-3.404 0 1.702 1.702 0 0 0 3.404 0z'
					fill='#FFF'
				/>
				<path
					d='M32.088 30.623c-1.334-1.143-2.953-1.912-5.243-.725-5.429 2.812-1.32 13.604 3.5 13.604 1.258 0 1.884 3.94-3.716 6.692 0-4.392-6.707-15.267-7.294-25.168-6.75-2.508-5.073-12.541-.439-12.541-.74-9.197 14.022-13.948 19.737-8.67C44.446-3.136 59.118.42 60.004 6.45c10.09-5.374 16.721 10.073 7.275 16.07-3.888 2.467-12.981 3.406-26.109 3.406-1.981 0-3.091.491-2.537 2.936 1.073 4.737-1.418 7.721-6.545 1.76z'
					fill='url(#b)'
					transform='matrix(-1 0 0 1 89.662 0)'
				/>
				<g>
					<path
						d='M84.142 82.342c.099.188.197.388.294.6l.195.44c.033.076.065.153.097.232l.193.488.192.519c.064.178.128.362.191.551l.19.583.189.614.188.647.093.335.186.693.185.726.184.757.091.39.182.806.181.836.18.869.179.9.178.932.176.963.176.996.26 1.552.173 1.075.257 1.672.17 1.154.17 1.185.167 1.218a5 5 0 0 1-4.955 5.671H0l.226-2.751.206-2.204.197-1.945.152-1.404.259-2.226.193-1.54.21-1.565.225-1.573.18-1.176.19-1.164.199-1.145.208-1.12.143-.73.148-.712.152-.693.078-.34.158-.662.162-.638.083-.31.168-.598c.341-1.168.707-2.163 1.097-2.908 1.482-2.833 5.985-5.926 11.422-8.821l1.03-.54 1.049-.536.53-.265 1.072-.526 1.082-.52 1.091-.51c.183-.085.365-.17.548-.253l1.097-.5 1.098-.489 1.095-.48 1.632-.698 1.61-.673 1.054-.433 1.548-.625 1.005-.398 1.458-.57 1.39-.533 2.141-.803 1.16-.427 1.696-.612 2.323-.814.623-.212.828.267 1.67.55 1.655.56 1.563.54 2.211.781 1.942.703 1.541.57 1.061.398 1.635.625 1.114.433 1.698.673 1.721.699 1.156.48 1.157.489 1.157.499 1.153.507c.191.085.383.17.574.257l1.14.519 1.128.526c.56.264 1.115.531 1.661.8l1.083.54c5.716 2.896 10.423 5.989 11.906 8.822z'
						fill='url(#c)'
						transform='translate(88.762 4.254)'
					/>
					<path
						d='m79.317 57-68.795-8.224c-3.392-17.81.631-29.031 12.069-33.664C27.878-2.024 48.457-.63 54.29.766 74.14 5.521 82.482 24.266 79.316 57z'
						fill='url(#d)'
						transform='translate(88.762 4.254)'
					/>
					<path
						d='M122.034 71.072V45.84c-10.467-.158-8.425-18.39 2.092-12.826 1.882.84 6.694-1.472 5.02-7.149 11.295 8.41 27.035-6.35 28.029 2.733 1.956 17.877-1.829 32.57-11.863 32.57-1.07.042-2.181-.022-3.238-.19l.04 10.093c-3.275 1.962-6.55 3.794-9.826 3.794-3.276 0-6.694-1.832-10.254-3.794z'
						fill='#FFDBC9'
					/>
					<path
						d='M142.074 61.175v3.482c-6.198 0-11.02-3.12-14.462-9.358 4.131 2.901 8.953 4.86 14.462 5.876z'
						fill='#FFBC9E'
					/>
					<path
						d='M132.136 74.112c2.91-.141 9.976-1.668 9.976-4.968v-2.786c.78 0 3.26 1.869 4.41 3.451 4.54 6.255-.496 11.863-4.605 11.863-5.183 0-8.618-4.393-9.767-6.505-1.149 2.114-4.584 6.505-9.767 6.505-4.109 0-9.146-5.608-4.605-11.863 1.15-1.582 3.383-3.45 4.163-3.45v2.785c0 3.3 7.12 4.827 10.195 4.968z'
						fill='#FFF'
					/>
				</g>
			</g>
		</SvgIcon>
	);
};

export { PeopleIcon };
