import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { SvgIcon } from "../../atoms/SvgIcon";

const useStyles = makeStyles({
	root: {
		height: "75vh",
		width: "100%",
	},
});

const WomanBigIcon = () => {
	const styles = useStyles();

	return (
		<SvgIcon className={styles.root} viewBox={"0 0 512 583"}>
			<svg xmlns='http://www.w3.org/2000/svg'>
				<defs>
					<linearGradient x1='50%' y1='0%' x2='50%' y2='100%' id='a'>
						<stop stopColor='#53629B' offset='0%' />
						<stop stopColor='#515790' offset='100%' />
					</linearGradient>
					<linearGradient x1='50%' y1='100%' x2='50%' y2='0%' id='b'>
						<stop stopColor='#D61B31' offset='0%' />
						<stop stopColor='#FF3000' offset='100%' />
					</linearGradient>
				</defs>
				<g fill='none' fillRule='evenodd'>
					<path d='M512 0H0v583h512z' />
					<g fillRule='nonzero'>
						<path
							d='M428.32 421.664c10.484 20.15 20.044 66.93 28.68 140.336H0s7.863-110.11 23.588-140.336c23.587-45.34 197.276-103.633 197.276-103.633s183.87 58.294 207.456 103.633z'
							fill='url(#a)'
							transform='translate(27 21)'
						/>
						<path
							d='M403.758 291.891 53.561 249.777c-17.266-91.204 3.213-148.666 61.435-172.389C141.911-10.367 246.668-3.228 276.361 3.925c101.048 24.348 143.513 120.336 127.397 287.966z'
							fill='url(#b)'
							transform='translate(27 21)'
						/>
						<path
							d='M196.371 363.168V233.963c-53.283-.808-42.89-94.174 10.647-65.68 9.583 4.306 34.074-7.537 25.555-36.609 57.497 43.069 137.62-32.516 142.68 13.998 9.956 91.544-9.308 166.787-60.389 166.79a85.03 85.03 0 0 1-16.48-.976l.205 51.682c-16.674 10.05-33.348 19.429-50.023 19.429-16.674 0-34.073-9.38-52.195-19.429z'
							fill='#FFDBC9'
						/>
						<path
							d='M298.383 312.487v17.83c-31.552 0-56.094-15.974-73.621-47.922 21.033 14.859 45.574 24.89 73.621 30.092z'
							fill='#FFBC9E'
						/>
						<path
							d='M247.793 378.737c14.814-.722 50.78-8.542 50.78-25.438v-14.268c3.973 0 16.6 9.57 22.45 17.671 23.116 32.032-2.524 60.748-23.438 60.748-26.387 0-43.874-22.493-49.72-33.312-5.847 10.826-23.334 33.312-49.721 33.312-20.913 0-46.555-28.716-23.438-60.748 5.849-8.101 17.219-17.671 21.19-17.671v14.268c0 16.896 36.248 24.716 51.897 25.438z'
							fill='#FFF'
						/>
					</g>
				</g>
			</svg>
		</SvgIcon>
	);
};

export { WomanBigIcon };
