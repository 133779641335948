import React from "react";
import { makeStyles } from "@material-ui/core";
import { Typography } from "../../atoms/Typography";
import { Container } from "../../atoms/Container";
import { PeopleIcon } from "../../icons/PeopleIcon";
import { Box } from "../../atoms/Box";
import { StartQuizButton } from "../../atoms/StartQuizButton";
import { TimerIcon } from "../../icons/TimerIcon";
import { Layout } from "../Layout";
import { useMobile } from "../../../hooks/useMobile";
import { WomanBigIcon } from "../../icons/WomanBigIcon";
import { ManBigIcon } from "../../icons/ManBigIcon";

const useStyles = makeStyles({
	rightIcon: {
		position: "absolute",
		bottom: "0",
		right: "-10%",
		zIndex: -1,
	},
	leftIcon: {
		position: "absolute",
		bottom: 0,
		left: "-10%",
		zIndex: -1,
	},
	container: {
		maxWidth: "40rem",
	},
});

const ASPECT_RATIO_BREAKPOINT = 1.55;

const LandingPageTemplate = () => {
	const { isMobile, aspectRatio } = useMobile();
	const styles = useStyles();
	const showBigIcons = aspectRatio >= ASPECT_RATIO_BREAKPOINT && !isMobile;

	return (
		<Layout>
			<Box>
				{showBigIcons ? (
					<Box display={"flex"} className={styles.leftIcon}>
						<WomanBigIcon />
					</Box>
				) : null}
				<Container className={styles.container}>
					<main>
						<Box
							marginTop={isMobile ? 3.5 : 13.75}
							marginBottom={3.75}
							display={"flex"}
							alignItems={"center"}
							justifyContent={"center"}
						>
							{aspectRatio < ASPECT_RATIO_BREAKPOINT || isMobile ? <PeopleIcon /> : null}
						</Box>
						<Box marginBottom={isMobile ? 3 : 2.5}>
							<Typography variant={"h1"} align={"center"}>
								Jaki jest Twój typ zawodowy?
							</Typography>
						</Box>
						<Box marginBottom={isMobile ? 3 : 7.5}>
							<Typography variant={"body1"} align={"center"}>
								Jakie rodzaje stanowisk mogą być Ci najbliższe? Sprawdź!
							</Typography>
						</Box>
						<Box marginBottom={3}>
							<Typography variant={"body2"} align={"center"}>
								Test opiera się na kwestionariuszu polskiego psychologa Sylwiusza
								Retowskiego. Twój wynik opiszemy na bazie sześciu orientacji
								zdefiniowanych przez amerykańskiego naukowca - Johna Hollanda.
							</Typography>
						</Box>
						<Box marginBottom={isMobile ? 3.5 : 5.25}>
							<Typography variant={"body2"} align={"center"}>
								Typ artystyczny, badawczy, konwencjonalny, realistyczny, przedsiębiorczy,
								a może społeczny? Dowiedz się, który z nich jest Twoim!
							</Typography>
						</Box>
						<Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
							<Box display={"flex"} alignItems={"center"} marginRight={1.25}>
								<TimerIcon />
							</Box>
							<Typography variant={"body1"}>Test zajmie Ci do 5 minut</Typography>
						</Box>
						<Box
							display={"flex"}
							alignItems={"center"}
							justifyContent={"center"}
							marginY={2}
						>
							<StartQuizButton />
						</Box>
					</main>
				</Container>
				{showBigIcons ? (
					<Box display={"flex"} className={styles.rightIcon}>
						<ManBigIcon />
					</Box>
				) : null}
			</Box>
		</Layout>
	);
};

export { LandingPageTemplate };
