import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { SvgIcon } from "../../atoms/SvgIcon";

const useStyles = makeStyles({
	root: {
		width: "1.125rem",
		height: "100%",
	},
});

const TimerIcon = () => {
	const styles = useStyles();

	return (
		<SvgIcon className={styles.root} color={"secondary"} viewBox={"0 0 18 18"}>
			<path
				d='M9 17.969a8.717 8.717 0 0 0 8.719-8.719A8.717 8.717 0 0 0 9 .531 8.717 8.717 0 0 0 .281 9.25 8.717 8.717 0 0 0 9 17.969zm0-1.688A7.03 7.03 0 0 1 1.969 9.25 7.03 7.03 0 0 1 9 2.219a7.03 7.03 0 0 1 7.031 7.031A7.03 7.03 0 0 1 9 16.281zm2.763-3.761.661-.911a.422.422 0 0 0-.091-.59l-2.349-1.71V4.33a.423.423 0 0 0-.422-.423H8.437a.423.423 0 0 0-.421.422v5.773c0 .133.063.26.172.34l2.985 2.17c.19.137.453.095.59-.091z'
				fillRule='nonzero'
			/>
		</SvgIcon>
	);
};

export { TimerIcon };
